import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { fullContacts } from "~/queries/contactsQuery"; // eslint-disable-line
import get from "lodash/get";
// import SocialNetworks from "../components/SocialNetworks";
// import CardList from "../components/CardList";
// import Card from "../components/Card";
// import MiniDrawer from "../components/Drawer/MiniDrawer";
// import FeaturedBlock from "../components/FeaturedBlock";
import IndexBlock from "../components/IndexBlock";
import PaginationBlock from "../components/PaginationBlockAtom";
import SEO from "../components/seo";

class PlacesIndex extends Component {
	render() {
		const posts = get(this, "props.data.recraft.entries");
		const { currentPage, numPages } = this.props.pageContext;
		const isFirst = currentPage === 1;
		const isLast = currentPage === numPages;
		const prevPage =
			currentPage - 1 === 1
				? "places/"
				: "places/" + (currentPage - 1).toString();
		const nextPage = "places/" + (currentPage + 1).toString();
		const featuredPost = posts[0];
		// const turquoise = "turquoise the color";
		return (
			<Layout>
				<SEO
					title="Places"
					keywords={[`dumbo`, `places`, `dumbo businesses`]}
				/>
				<IndexBlock posts={posts} featuredPost={featuredPost} />
				<PaginationBlock
					nextPage={nextPage}
					prevPage={prevPage}
					numPages={numPages}
					currentPage={currentPage}
					isFirst={isFirst}
					isLast={isLast}
					linkWord={"places"}
				/>
			</Layout>
		);
	}
}

export default PlacesIndex;

export const listingsListQuery = graphql`
	query listingsListQuery($offset: Int!, $limit: Int!) {
		recraft {
			entries(section: [listings], limit: $limit, offset: $offset) {
				... on Recraft_Listings {
					title
					uri
					slug
					featured
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
					body {
						content
					}
					locationCategory {
						title
						friendlyName
					}
					preparseGlobalCategory
					postDate
					primaryContact {
						phone
					}
					addressSelector {
						... on Recraft_Addresses {
							streetNumber
							streetName
							streetType
							title
						}
					}
				}
				...fullContacts
			}
		}
	}
`;

// const posts = get(this, "props.data.craft.entries");

// <SocialNetworks
// 											items={entry.socializr}
// 											featured="featured"
// 										/>

// <div className="w-full flex px-4 mb-6">
// <h2>Title</h2>
// <h4>{totalCount}</h4>
// </div>
